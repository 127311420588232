<template>
    <div class="content">
        <div class="title">Информация о менеджере</div>
        <div class="info">
            <div class="fields">
                <div class="field">{{ user.fio }}</div>
                <div class="field">
                    <SelectBox
                        v-model="userStore"
                        label="Склад по-умолчанию"
                        :options="this.stores"
                        @change="changeStore"
                    ></SelectBox>
                </div>
                <div class="field">
                    <SelectBox
                        v-model="userPrice"
                        label="Цена по-умолчанию"
                        :options="this.prices"
                        @change="changePrice"
                    ></SelectBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectBox from "../components/fields/SelectBox";
import { DictionariesService } from "../services/dictionaries.service";
import { AuthService } from "../services/auth.service";

export default {
    name: "ManagerInfo",
    components: {SelectBox},
    methods: {
        changeStore() {
            this.authService.changeStore(this.userStore);
        },
        changePrice() {
            this.authService.changePrice(this.userPrice);
        }
    },
    watch: {
        'authService.user': function (value) {
            this.user = value
            this.userStore = this.user['store_id'];
            this.userPrice = this.user['price_id'];
        }
    },
    computed: {
        stores() {
            let list = {};
            this.dictionariesService.stores.map(store => {
                list[store.id] = store.name;
            })
            return list;
        },
        prices() {
            let list = {};
            this.dictionariesService.prices.map(price => {
                list[price.id] = price.name;
            })
            return list;
        }
    },
    created() {
        this.user = this.authService.user;
        this.userStore = this.authService.getStoreId();
        this.userPrice = this.authService.getPriceId();
    },
    data() {
        return {
            authService: AuthService,
            dictionariesService: DictionariesService,
            user: {},
            userStore: null,
            userPrice: null,
        }
    }
}
</script>

<style scoped>

</style>